/* You can add global styles to this file, and also import other style files */

// Font: Poppins
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');

// This injects Tailwind's base styles
@tailwind base;
// This injects Tailwind's component classes
@tailwind components;
// This injects Tailwind's utility classes
@tailwind utilities;

// Use the @layer directive to tell Tailwind which “bucket” a set of custom styles belong to. Valid layers are a "base", "components", and "utilities".
@layer base {
  //* TEXT
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  label,
  input {
    @apply font-poppins;
  }
  h1 {
    @apply text-3xl font-bold; // font-size: 30px
  }
  h2 {
    @apply text-2xl font-bold; // font-size: 24px
  }
  h3 {
    @apply text-xl font-semibold; // font-size: 20px
  }
  h4 {
    @apply text-base font-semibold; // font-size: 16px
  }
  h5 {
    @apply text-sm font-semibold; // font-size: 14px
  }
  p {
    @apply text-xs font-normal; // font-size: 12px
  }
  h6 {
    @apply text-xxs font-normal; // font-size: 9px, font-weight: regular
  }

  a {
    @apply text-main;
  }

  hr {
    @apply border-t border-solid border-gray-200;
  }

  //* FORMS
  .label {
    @apply text-sm;
  }
  .input {
    @apply w-full h-12 px-5 py-2 mt-2 block border border-gray-200 rounded-full;
    &::placeholder {
      @apply text-xs;
    }
    &-date {
      @apply text-xs;
    }
  }

  //* SCROLLBAR
  /* width */
  ::-webkit-scrollbar {
    @apply w-2;
    // width: 10px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-white bg-gray-200;
    // @apply bg-white bg-opacity-20;
    // background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-white bg-gray-300 rounded-full;
    // @apply bg-white bg-opacity-40 rounded-full;
    // background: #888;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-white bg-gray-400;
    // @apply bg-white bg-opacity-60;
    // background: #555;
  }
  .flipped-ltr {
    direction: ltr;
  }
  .flipped-rtl {
    direction: rtl;
  }
}

@layer components {
  //* BUTTONS
  .btn {
    @apply w-28 min-w-max px-4 py-2 font-poppins text-sm font-normal text-white rounded-full flex justify-center items-center;
    &:disabled {
      @apply cursor-default;
    }
  }
  .btn-main {
    @apply btn h-10 bg-main;
  }
  .btn-main-reverse {
    @apply btn h-10 text-main border border-main bg-white;
    // @apply btn h-10 text-main border border-main bg-transparent;
  }
  .btn-white {
    @apply btn h-10 text-black bg-white;
  }
  .btn-white-reverse {
    @apply btn h-10 border border-white;
  }
  .btn-icon {
    @apply w-9 h-9 flex justify-center items-center bg-gray-100 rounded-full;
  }
  .btn-main-dark {
    @apply btn h-9 bg-main-dark;
  }
  .btn-black {
    @apply btn w-40 h-12 bg-black;
  }
  .btn-opacity {
    @apply btn w-auto h-10 bg-black backdrop-filter backdrop-blur;
  }

  //* BOXES
  .box-white {
    @apply bg-white rounded-2.5xl;
    height: fit-content;
    .box-header {
      @apply px-6 py-4 flex items-center border-b border-gray-200;
      height: 88px;
    }
    .box-header-center {
      @apply px-6 py-4 flex justify-center items-center border-b border-gray-200;
      height: 88px;
    }
    .box-body {
      @apply px-4 py-2;
    }
    .box-footer {
      @apply h-14 px-6 py-4 relative bottom-0 flex justify-end items-center border-t border-gray-200;
    }
    .box-footer-center {
      @apply h-14 px-6 py-4 relative bottom-0 flex justify-center items-center border-t border-gray-200;
    }
  }
  .box-main {
    @apply box-white h-auto text-white bg-main-dark;
    // height: auto;
  }

  // Npn Multi Range Slider
  // .slider {
  //   display: flex !important;
  //   align-items: center !important;
  //   .bar {
  //     height: 5px !important;
  //     display: flex !important;
  //     align-items: center !important;
  //     .left-handle, .right-handle {
  //       margin-top: 1px !important;
  //       width: 16px !important;
  //       height: 16px !important;
  //       border: 1px solid white !important;
  //       background-color: #005A9C !important;
  //       .handle-tooltip {
  //         top: -30px !important;
  //         left: -7px !important;
  //         color: #005A9C !important;
  //         border-color: #005A9C !important;
  //         background-color: #F2F6FA !important;
  //         &::after {  // &::before,
  //           border-top-color: #005A9C !important;
  //         }
  //       }
  //     }
  //     .filler {
  //       span {
  //         background-color: #005A9C !important;
  //       }
  //     }
  //   }
  //   .values {
  //     display: none !important;
  //   }
  // }
}

// @layer utilities {
// }

// POPOVER DVM
.dvmPopover {
  // border-radius: 12px;
  // padding: 4px;
  // max-width: 345px;
  display: none;

  &[data-show] {
    display: block;
  }
  &[data-popper-placement^='top'] {
    #arrow {
      bottom: 2px;
    }
  }
  &[data-popper-placement^='bottom'] {
    #arrow {
      top: 2px;
    }
  }
  #arrow {
    position: absolute;
    width: 12px;
    height: 12px;
    z-index: -1;
    &::before {
      position: absolute;
      width: 12px;
      height: 12px;
      z-index: -1;
      content: '';
      transform: rotate(45deg);
      background: black;
      // background: rgb(39, 39, 42);  // bg-gray-800
    }
  }
}

// // POPOVER TUTORIAL
// #tutorialPopover {
//   // display: none;
//   position: absolute;
//   #arrow-top {
//     @apply w-4 h-4 absolute top-0 left-1/2 bg-black transform -translate-x-1/2 rotate-45;
//   }
//   &-box {
//     @apply w-64 p-6 bg-black rounded-2.5xl;
//   }
// }

// .tutorialPopover {
//   border-radius: 20px;
//   padding: 24px;
//   width: 288px;
//   display: none;

//   &[data-show] {
//     display: block;
//   }
//   &[data-popper-placement^='top'] {
//     #arrow {
//       bottom: -2px;
//     }
//   }
//   &[data-popper-placement^='bottom'] {
//     #arrow {
//       top: -2px;
//     }
//   }
//   #arrow {
//     &::before {
//       position: absolute;
//       width: 12px;
//       height: 12px;
//       z-index: -1;
//       content: '';
//       transform: rotate(45deg);
//       background: #000000;  // bg-gray-800
//     }
//   }
// }

// LOADING BAR
ngx-loading-bar {
  position: unset !important;
  pointer-events: unset !important;
  color: #005a9c !important;
  z-index: 9999;
}
.ngx-spinner {
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  background-color: white;
  opacity: 0.7;
  z-index: 9999;
}
.ngx-spinner-icon {
  position: absolute !important;
  border-width: 7px !important;
  top: calc(50% - 7px) !important;
  left: calc(50% - 7px) !important;
  //transform: translate(-50%, -50%)!important;
  width: 80px !important;
  height: 80px !important;
  z-index: 9999;
}

// Driver.js Popover Styles
.driver-popover.driverjs-theme {
  background-color: rgb(24, 24, 24);
  color: white;
}
.driver-popover.driverjs-theme .driver-popover-description {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
}
.driver-popover.driverjs-theme .driver-popover-close-btn {
  color: white;
  font-size: 1.3rem;
  height: 24px;
  width: 24px;
}
.driver-popover.driverjs-theme .driver-popover-footer {
  border-top: 1px solid rgb(39, 39, 24);
  gap: 1rem;
  padding-top: 0.5rem;
}
.driver-popover.driverjs-theme .driver-popover-footer button {
  text-shadow: none;
}
.driver-popover.driverjs-theme .driver-popover-navigation-btns {
  display: flex;
  justify-content: space-between;
}
.driver-popover.driverjs-theme .driver-popover-prev-btn {
  @extend .btn-white-reverse;
  background: transparent;
  width: auto;
}
.driver-popover.driverjs-theme .driver-popover-next-btn {
  @extend .btn-white;
  width: auto;
}
